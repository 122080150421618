//=====================================
//            All Componenets
//=====================================
// ------ Dashboard ------- 
import Dashboard from './pages/Dashboard/dashboardv1';
import DashboardV2 from './pages/Dashboard/dashboardv2';
import DashboardV3 from './pages/Dashboard/dashboardv3';

// ------ UI Elements ------- 
import Alert from './pages/UI-Elements/alert';
import Buttons from './pages/UI-Elements/buttons';
import Badges from './pages/UI-Elements/badges';
import Cards from './pages/UI-Elements/cards';
import Carousel from './pages/UI-Elements/carousel';
import Dropdown from './pages/UI-Elements/dropdown';
import Listgroup from './pages/UI-Elements/list-group';
import Modals from './pages/UI-Elements/modals';
import Paginate from './pages/UI-Elements/pagination';
import Progressbar from './pages/UI-Elements/progressbar';
import Tabs from './pages/UI-Elements/tabs';
import Typography from './pages/UI-Elements/typography';
import Grid from './pages/UI-Elements/grid';

// ------ Advanced Kit ------- 
import SweetAlert from './pages/Advanced-Kit/sweet-alert';
import Cropper from './pages/Advanced-Kit/cropper';
import Loaders from './pages/Advanced-Kit/loaders';
import LaddaButtons from './pages/Advanced-Kit/ladda-button';
import Dropzone from './pages/Advanced-Kit/dropzone';

// ------ Icons ------- 
import FontAwesome from './pages/Icons/font-awesome';
import Themify from './pages/Icons/themify';
import Ionicons from './pages/Icons/ionicons';
import EtLine from './pages/Icons/et-line';

// ------ Charts ------- 
import Chartjs from './pages/Charts/chartjs';
import Chartist from './pages/Charts/chartist';
import C3charts from './pages/Charts/c3charts';
import Echart from './pages/Charts/echart';
import Apex from './pages/Charts/apex';

// ------ Forms ------- 
import FormBasic from './pages/Forms/form-basic';
import FormLayouts from './pages/Forms/form-layouts';
import FormGroups from './pages/Forms/form-groups';

// ------ Tables ------- 
import BasicTable from './pages/Tables/basic-table';
import DataTable from './pages/Tables/datatable';

// ------ Maps ------- 
import GoogleMaps from './pages/Maps/maps-google';
import VectorMaps from './pages/Maps/maps-vector';

// ------ Editors ------- 
import TextEditor from './pages/Editors/text-editor';
import CodeEditor from './pages/Editors/code-editor';

// ------ Apps ------- 
import Gallery from './pages/Apps/gallery';
import Calendar from './pages/Calendar/calendar';

// ------ Email ------- 
import Inbox from './pages/Email/inbox';
import Compose from './pages/Email/compose';
import ReadMail from './pages/Email/read-mail';

// ------ Error Pages ------- 
import PageNotFound from './pages/Error/404';
import InternalServerErrorPage from './pages/Error/500';
import InternalServerPage from './pages/Error/505';

// ------ Other Pages ------- 
import BlankPage from './pages/Others/blank';
import InvoicePage from './pages/Others/invoice';
import Pricing from './pages/Others/pricing';
import Timeline from './pages/Others/timeline';

// ------ Auth Pages ------- 
import Pageslogin from './pages/Auth/Login';
import Pagesregister from './pages/Auth/Register';
import ForgetPassword from './pages/Auth/ForgetPassword';
import LockScreen from './pages/Auth/Lock';
import ResetPassword from './pages/Auth/ResetPassword';
import Logout from './pages/Auth/Logout';

const routes = [

    //=====================================
    //            Public Routes
    //=====================================
    { path: '/login', component: Pageslogin, ispublic: true },
    { path: '/logout', component: Logout, ispublic: true },
    { path: '/register', component: Pagesregister, ispublic: true },
    { path: '/forget-password', component: ForgetPassword, ispublic: true },
    { path: '/lock', component: LockScreen, ispublic: true },
    { path: '/reset-password', component: ResetPassword, ispublic: true },

    //=====================================
    //            UI Elements
    //=====================================
    { path: '/alert', component: Alert },
    { path: '/buttons', component: Buttons },
    { path: '/badges', component: Badges },
    { path: '/cards', component: Cards },
    { path: '/carousel', component: Carousel },
    { path: '/dropdown', component: Dropdown },
    { path: '/list-group', component: Listgroup },
    { path: '/modals', component: Modals },
    { path: '/pagination', component: Paginate },
    { path: '/progressbar', component: Progressbar },
    { path: '/tabs', component: Tabs },
    { path: '/typography', component: Typography },
    { path: '/grid', component: Grid },

    //=====================================
    //            Advance Kit
    //=====================================
    { path: '/toastr', component: Dashboard },
    { path: '/sweet-alert', component: SweetAlert },
    { path: '/cropper', component: Cropper },
    { path: '/loaders', component: Loaders },
    { path: '/ladda-button', component: LaddaButtons },
    { path: '/dropzone', component: Dropzone },

    //=====================================
    //            Icons
    //=====================================
    { path: '/font-awesome', component: FontAwesome },
    { path: '/themify', component: Themify },
    { path: '/ionicons', component: Ionicons },
    { path: '/et-line', component: EtLine },

    //=====================================
    //            Maps
    //=====================================
    { path: '/google-maps', component: GoogleMaps },
    { path: '/vector-maps', component: VectorMaps },

    //=====================================
    //            Tables
    //=====================================
    { path: '/basic-table', component: BasicTable },
    { path: '/datatable', component: DataTable },

    //=====================================
    //            Forms
    //=====================================
    { path: '/form-basic', component: FormBasic },
    { path: '/form-layouts', component: FormLayouts },
    { path: '/form-groups', component: FormGroups },

    //=====================================
    //            Editors
    //=====================================
    { path: '/text-editor', component: TextEditor },
    { path: '/code-editor', component: CodeEditor },

    //=====================================
    //            Calendar
    //=====================================
    { path: '/full-calendar', component: Calendar },

    //=====================================
    //            Charts
    //=====================================
    { path: '/chart-js', component: Chartjs },
    { path: '/echart', component: Echart },
    { path: '/apex', component: Apex },
    { path: '/c3-chart', component: C3charts },
    { path: '/chartist', component: Chartist },

    //=====================================
    //            Email
    //=====================================
    { path: '/inbox', component: Inbox },
    { path: '/compose', component: Compose },
    { path: '/read-mail', component: ReadMail },

    //=====================================
    //            Gallery
    //=====================================
    { path: '/gallery', component: Gallery },

    //=====================================
    //            Error Pages
    //=====================================
    { path: '/404', component: PageNotFound },
    { path: '/500', component: InternalServerErrorPage },
    { path: '/505', component: InternalServerPage },

    //=====================================
    //            Other Pages
    //=====================================
    { path: '/blank', component: BlankPage },
    { path: '/invoice', component: InvoicePage },
    { path: '/pricing', component: Pricing },
    { path: '/timeline', component: Timeline },

    //=====================================
    //            Dashboard
    //=====================================
    { path: '/dashboard', component: Dashboard },
    { path: '/dashboard-2', component: DashboardV2 },
    { path: '/dashboard-3', component: DashboardV3 },
    { path: '/', component: Pageslogin, ispublic: true },

];

export default routes;