import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Vector from './Vectormap/Vectormap';
import RecentSaleChart from './HomeCharts/recentSaleChart';
import CurrentSaleChart from './HomeCharts/currentSaleChart';
import CurrentYearSaleChart from './HomeCharts/currentYearSaleChart';
import { MDBDataTable } from 'mdbreact';
import Pie from './HomeCharts/pie';

class DashboardV2 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Position',
                    field: 'position',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Office',
                    field: 'office',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Age',
                    field: 'age',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Start date',
                    field: 'date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Salary',
                    field: 'salary',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: [
                {
                    name: 'Angelica Ramos',
                    position: 'Chief Executive Officer (CEO)',
                    office: 'London',
                    age: '47',
                    date: '2009/10/09',
                    salary: '$1'
                },
                {
                    name: 'Gavin Joyce',
                    position: 'Developer',
                    office: 'Edinburgh',
                    age: '42',
                    date: '2010/12/22',
                    salary: '$92'
                },
                {
                    name: 'Jennifer Chang',
                    position: 'Regional Director',
                    office: 'Singapore',
                    age: '28',
                    date: '2010/11/14',
                    salary: '$357'
                },
                {
                    name: 'Brenden Wagner',
                    position: 'Software Engineer',
                    office: 'San Francisco',
                    age: '28',
                    date: '2011/06/07',
                    salary: '$206'
                },
                {
                    name: 'Fiona Green',
                    position: 'Chief Operating Officer (COO)',
                    office: 'San Francisco',
                    age: '48',
                    date: '2010/03/11',
                    salary: '$850'
                },
                {
                    name: 'Shou Itou',
                    position: 'Regional Marketing',
                    office: 'Tokyo',
                    age: '20',
                    date: '2011/08/14',
                    salary: '$163'
                },
                {
                    name: 'Michelle House',
                    position: 'Integration Specialist',
                    office: 'Sidney',
                    age: '37',
                    date: '2011/06/02',
                    salary: '$95'
                },
                {
                    name: 'Suki Burks',
                    position: 'Developer',
                    office: 'London',
                    age: '53',
                    date: '2009/10/22',
                    salary: '$114'
                },
                {
                    name: 'Prescott Bartlett',
                    position: 'Technical Author',
                    office: 'London',
                    age: '27',
                    date: '2011/05/07',
                    salary: '$145'
                },
                {
                    name: 'Gavin Cortez',
                    position: 'Team Leader',
                    office: 'San Francisco',
                    age: '22',
                    date: '2008/10/26',
                    salary: '$235'
                },
                {
                    name: 'Martena Mccray',
                    position: 'Post-Sales support',
                    office: 'Edinburgh',
                    age: '46',
                    date: '2011/03/09',
                    salary: '$324'
                },
                {
                    name: 'Unity Butler',
                    position: 'Marketing Designer',
                    office: 'San Francisco',
                    age: '47',
                    date: '2009/12/09',
                    salary: '$85'
                },
                {
                    name: 'Howard Hatfield',
                    position: 'Office Manager',
                    office: 'San Francisco',
                    age: '51',
                    date: '2008/12/16',
                    salary: '$164'
                },
                {
                    name: 'Hope Fuentes',
                    position: 'Secretary',
                    office: 'San Francisco',
                    age: '41',
                    date: '2010/02/12',
                    salary: '$109'
                },
                {
                    name: 'Vivian Harrell',
                    position: 'Financial Controller',
                    office: 'San Francisco',
                    age: '62',
                    date: '2009/02/14',
                    salary: '$452'
                },
                {
                    name: 'Timothy Mooney',
                    position: 'Office Manager',
                    office: 'London',
                    age: '37',
                    date: '2008/12/11',
                    salary: '$136'
                },
                {
                    name: 'Jackson Bradshaw',
                    position: 'Director',
                    office: 'New York',
                    age: '65',
                    date: '2008/09/26',
                    salary: '$645'
                },
                {
                    name: 'Olivia Liang',
                    position: 'Support Engineer',
                    office: 'Singapore',
                    age: '64',
                    date: '2011/02/03',
                    salary: '$234'
                },
                {
                    name: 'Bruno Nash',
                    position: 'Software Engineer',
                    office: 'London',
                    age: '38',
                    date: '2011/05/03',
                    salary: '$163'
                },
                {
                    name: 'Sakura Yamamoto',
                    position: 'Support Engineer',
                    office: 'Tokyo',
                    age: '37',
                    date: '2009/08/19',
                    salary: '$139'
                },
                {
                    name: 'Thor Walton',
                    position: 'Developer',
                    office: 'New York',
                    age: '61',
                    date: '2013/08/11',
                    salary: '$98'
                },
                {
                    name: 'Finn Camacho',
                    position: 'Support Engineer',
                    office: 'San Francisco',
                    age: '47',
                    date: '2009/07/07',
                    salary: '$87'
                },
                {
                    name: 'Serge Baldwin',
                    position: 'Data Coordinator',
                    office: 'Singapore',
                    age: '64',
                    date: '2012/04/09',
                    salary: '$138'
                },
                {
                    name: 'Zenaida Frank',
                    position: 'Software Engineer',
                    office: 'New York',
                    age: '63',
                    date: '2010/01/04',
                    salary: '$125'
                },
                {
                    name: 'Zorita Serrano',
                    position: 'Software Engineer',
                    office: 'San Francisco',
                    age: '56',
                    date: '2012/06/01',
                    salary: '$115'
                },
                {
                    name: 'Jennifer Acosta',
                    position: 'Junior Javascript Developer',
                    office: 'Edinburgh',
                    age: '43',
                    date: '2013/02/01',
                    salary: '$75'
                },
                {
                    name: 'Cara Stevens',
                    position: 'Sales Assistant',
                    office: 'New York',
                    age: '46',
                    date: '2011/12/06',
                    salary: '$145'
                },
                {
                    name: 'Hermione Butler',
                    position: 'Regional Director',
                    office: 'London',
                    age: '47',
                    date: '2011/03/21',
                    salary: '$356'
                },
                {
                    name: 'Lael Greer',
                    position: 'Systems Administrator',
                    office: 'London',
                    age: '21',
                    date: '2009/02/27',
                    salary: '$103'
                },
                {
                    name: 'Jonas Alexander',
                    position: 'Developer',
                    office: 'San Francisco',
                    age: '30',
                    date: '2010/07/14',
                    salary: '$86'
                },
                {
                    name: 'Shad Decker',
                    position: 'Regional Director',
                    office: 'Edinburgh',
                    age: '51',
                    date: '2008/11/13',
                    salary: '$183'
                },
                {
                    name: 'Michael Bruce',
                    position: 'Javascript Developer',
                    office: 'Singapore',
                    age: '29',
                    date: '2011/06/27',
                    salary: '$183'
                },
                {
                    name: 'Donna Snider',
                    position: 'Customer Support',
                    office: 'New York',
                    age: '27',
                    date: '2011/01/25',
                    salary: '$112'
                }
            ]
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="4" className="stretched_card">
                        <Card>
                            <CardBody>
                                <p className="card-title mb-1 text-muted">Recent Month Sales</p>
                                <div className="d-flex flex-wrap align-items-baseline">
                                    <h2 className="mr-3">89,99 $</h2>
                                    <i className="feather ft-arrow-up mr-1 text-success"></i><span><cite className="mb-0 text-success font-weight-medium">4.89%</cite></span>
                                </div>
                                <p className="mb-0 text-muted">World wide Sales</p>
                            </CardBody>
                            <RecentSaleChart />
                        </Card>
                    </Col>
                    <Col lg="4" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <p className="card-title mb-1 text-muted">Current Month Sales</p>
                                <div className="d-flex flex-wrap align-items-baseline">
                                    <h2 className="mr-3">19,89 $</h2>
                                    <i className="feather ft-arrow-down mr-1 text-danger"></i><span><cite className="mb-0 text-danger font-weight-medium">1.12%</cite></span>
                                </div>
                                <p className="mb-0 text-muted">World wide Sales</p>
                            </CardBody>
                            <CurrentSaleChart />
                        </Card>
                    </Col>
                    <Col lg="4" className="stretched_card mt-mob-4">
                        <Card>
                            <CardBody>
                                <p className="card-title mb-1 text-muted">Current Year Sales</p>
                                <div className="d-flex flex-wrap align-items-baseline">
                                    <h2 className="mr-3">12,56 $</h2>
                                    <i className="feather ft-arrow-down mr-1 text-danger"></i><span><cite className="mb-0 text-danger font-weight-medium">1.12%</cite></span>
                                </div>
                                <p className="mb-0 text-muted">World wide Sales</p>
                            </CardBody>
                            <CurrentYearSaleChart />
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="8" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Vist by Country</h4>
                                <div className="home_map">
                                    <Vector value="world_mill" width="400" color="#999999" />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="4" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">User Stats</h4>
                                <Pie />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">DATA TABLE DEFAULT</h4>
                                <div className="datatable_container">
                                    <MDBDataTable
                                        responsive
                                        bordered
                                        data={data}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Timeline</h4>
                                <div className="pl-5">
                                    <div className="timeline-task">
                                        <div className="icon bg_secondary">
                                            <i className="feather ft-message-square"></i>
                                        </div>
                                        <div className="timeline_title">
                                            <h4>You got 1 New Message</h4>
                                            <span className="time"><i className="ti-time"></i>09:35</span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse distinctio itaque at.
                                    </p>
                                    </div>
                                    <div className="timeline-task">
                                        <div className="icon bg_success">
                                            <i className="feather ft-check"></i>
                                        </div>
                                        <div className="timeline_title">
                                            <h4>Your Verification Successful</h4>
                                            <span className="time"><i className="ti-time"></i>7 Minutes Ago</span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur.
                                    </p>
                                    </div>
                                    <div className="timeline-task">
                                        <div className="icon bg_danger">
                                            <i className="feather ft-alert-triangle"></i>
                                        </div>
                                        <div className="timeline_title">
                                            <h4>Something Went Wrong</h4>
                                            <span className="time"><i className="ti-time"></i>09:20 Am</span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse distinctio itaque at.</p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Sales By Traffic</h4>
                                <ul className="traffic-sales list-group list-group-flush">
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Direct</span>
                                        <span className="traffic-sales-amount">$4000.00
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                                <i className="fa fa-fw fa-arrow-up"></i>
                                            </span>
                                            <span className="ml-1 text-success">5.86%</span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item">
                                        <span className="traffic-sales-name">Search
                                        <span className="traffic-sales-amount">$3123.00
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                                    <i className="fa fa-fw fa-arrow-up"></i>
                                                </span>
                                                <span className="ml-1 text-success">5.86%</span>
                                            </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item">
                                        <span className="traffic-sales-name">Social
                                        <span className="traffic-sales-amount ">$3099.00
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                                    <i className="fa fa-fw fa-arrow-up"></i>
                                                </span>
                                                <span className="ml-1 text-success">5.86%</span>
                                            </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item">
                                        <span className="traffic-sales-name">Referrals
                                        <span className="traffic-sales-amount ">$2220.00
                                            <span className="icon-circle-small icon-box-xs text-danger ml-4 bg-danger-light">
                                                    <i className="fa fa-fw fa-arrow-down"></i>
                                                </span>
                                                <span className="ml-1 text-danger">4.02%</span>
                                            </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Email
                                        <span className="traffic-sales-amount">$1567.00
                                            <span className="icon-circle-small icon-box-xs text-danger ml-4 bg-danger-light">
                                                    <i className="fa fa-fw fa-arrow-down"></i>
                                                </span>
                                                <span className="ml-1 text-danger">3.86%</span>
                                            </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Visits
                                        <span className="traffic-sales-amount">$1567.00
                                            <span className="icon-circle-small icon-box-xs text-danger ml-4 bg-danger-light">
                                                    <i className="fa fa-fw fa-arrow-down"></i>
                                                </span>
                                                <span className="ml-1 text-danger">3.86%</span>
                                            </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Marketing
                                        <span className="traffic-sales-amount">$1567.00
                                            <span className="icon-circle-small icon-box-xs text-danger ml-4 bg-danger-light">
                                                    <i className="fa fa-fw fa-arrow-down"></i>
                                                </span>
                                                <span className="ml-1 text-danger">3.86%</span>
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(DashboardV2));