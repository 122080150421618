import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Polarchart from '../InitCharts/chartist/polarchart';
import StackBarChart from '../InitCharts/chartist/stackedbarchart';
import DountChart from '../InitCharts/chartist/dountchart';
import PieChart from '../InitCharts/chartist/piechart';
import SmilAnimationsChart from '../InitCharts/chartist/smilanimations';
import LineChart from '../InitCharts/chartist/linechart';
import ChartBar from '../InitCharts/chartist/chartbar';
import LineAreaChart from '../InitCharts/chartist/lineareachart';

import '../../assets/css/chartist.css';

class Chartist extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Simple Line Chart</h4>
                                {/* <BarChart /> */}
                                <div className="ct-golden-section">
                                    <LineChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="mt-mob-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">LINE CHART WITH AREA</h4>
                                <div className="ct-golden-section">
                                    <LineAreaChart />
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Whole Numbers</h4>
                                <div className="ct-golden-section">
                                    <SmilAnimationsChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Stacked Bar Chart</h4>
                                <div className="ct-golden-section">
                                    <Polarchart />
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">STACKED BAR CHART</h4>
                                <div className="ct-golden-section">
                                    <StackBarChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">MULTI-LINE LABELS</h4>
                                <div className="ct-golden-section">
                                    <ChartBar />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Pie Chart</h4>
                                <div className="ct-golden-section">
                                    <PieChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Donut Chart</h4>
                                <div className="ct-golden-section">
                                    <DountChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(Chartist));