import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

class BarChart extends Component {

    render() {
        const data = {
            labels: ["January", "February", "March", "April", "May", "June", "July"],
            datasets: [{
                label: 'Profit Months',
                data: [12, 19, 3, 17, 15, 24, 7],
                backgroundColor: "#d5c0f2",
                borderColor: "transparent",
                borderWidth: 0
            }, {
                label: 'Loss Month',
                data: [15, 29, 5, 5, 20, 3, 10],
                backgroundColor: "#5c2b87",
                borderColor: "transparent",
                borderWidth: 0
            }]
        };

        const option = {
            maintainAspectRatio: false,
            legend: {
                display: false
            },

            scales: {
                xAxes: [{
                    ticks: {
                        fontSize: 14,
                        fontColor: '#71748d',
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontSize: 14,
                        fontColor: '#71748d',
                    }
                }]
            }
        }

        return (
            <React.Fragment>
                <Bar width={600} height={245} data={data} options={option} />
            </React.Fragment>
        );
    }
}

export default BarChart;   