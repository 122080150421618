import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

class RecentSaleChart extends Component {

    render() {
        const data = {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
            datasets: [{
                data: [160, 105, 225, 140, 180, 61, 120, 60, 90],
                backgroundColor: [
                    '#b9e8f9'
                ],
                borderWidth: 2,
                borderColor: "#04C4DC",
                fill: 'origin',
                label: "purchases"
            }]
        };

        const option = {
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
                filler: {
                    propagate: false
                }
            },
            scales: {
                xAxes: [{
                    display: false,
                    ticks: {
                        display: true
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false,
                        color: 'transparent',
                        zeroLineColor: '#eeeeee'
                    }
                }],
                yAxes: [{
                    display: false,
                    ticks: {
                        display: true,
                        autoSkip: false,
                        maxRotation: 0,
                        stepSize: 100,
                        min: 0,
                        max: 300
                    },
                    gridLines: {
                        drawBorder: false
                    }
                }]
            },
            legend: {
                display: false
            },
            tooltips: {
                enabled: true
            },
            elements: {
                line: {
                    tension: .35
                },
                point: {
                    radius: 0
                }
            }
        }

        return (
            <React.Fragment>
                <Line width={600} height={245} data={data} options={option} />
            </React.Fragment>
        );
    }
}

export default RecentSaleChart;   