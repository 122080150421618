import React, { Component } from 'react';
import { Row, Col, Table, Card, CardBody, CardFooter } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import member_1 from "../../assets/images/team/member1.jpg";
import Pie from './HomeCharts/pie';

class DashboardV3 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.activateAuthLayout();
    }

    render() {
        const data = {
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Position',
                    field: 'position',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Office',
                    field: 'office',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Age',
                    field: 'age',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Start date',
                    field: 'date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Salary',
                    field: 'salary',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: [
                {
                    name: 'Angelica Ramos',
                    position: 'Chief Executive Officer (CEO)',
                    office: 'London',
                    age: '47',
                    date: '2009/10/09',
                    salary: '$1'
                },
                {
                    name: 'Gavin Joyce',
                    position: 'Developer',
                    office: 'Edinburgh',
                    age: '42',
                    date: '2010/12/22',
                    salary: '$92'
                },
                {
                    name: 'Jennifer Chang',
                    position: 'Regional Director',
                    office: 'Singapore',
                    age: '28',
                    date: '2010/11/14',
                    salary: '$357'
                },
                {
                    name: 'Brenden Wagner',
                    position: 'Software Engineer',
                    office: 'San Francisco',
                    age: '28',
                    date: '2011/06/07',
                    salary: '$206'
                },
                {
                    name: 'Fiona Green',
                    position: 'Chief Operating Officer (COO)',
                    office: 'San Francisco',
                    age: '48',
                    date: '2010/03/11',
                    salary: '$850'
                },
                {
                    name: 'Shou Itou',
                    position: 'Regional Marketing',
                    office: 'Tokyo',
                    age: '20',
                    date: '2011/08/14',
                    salary: '$163'
                },
                {
                    name: 'Michelle House',
                    position: 'Integration Specialist',
                    office: 'Sidney',
                    age: '37',
                    date: '2011/06/02',
                    salary: '$95'
                },
                {
                    name: 'Suki Burks',
                    position: 'Developer',
                    office: 'London',
                    age: '53',
                    date: '2009/10/22',
                    salary: '$114'
                },
                {
                    name: 'Prescott Bartlett',
                    position: 'Technical Author',
                    office: 'London',
                    age: '27',
                    date: '2011/05/07',
                    salary: '$145'
                },
                {
                    name: 'Gavin Cortez',
                    position: 'Team Leader',
                    office: 'San Francisco',
                    age: '22',
                    date: '2008/10/26',
                    salary: '$235'
                },
                {
                    name: 'Martena Mccray',
                    position: 'Post-Sales support',
                    office: 'Edinburgh',
                    age: '46',
                    date: '2011/03/09',
                    salary: '$324'
                },
                {
                    name: 'Unity Butler',
                    position: 'Marketing Designer',
                    office: 'San Francisco',
                    age: '47',
                    date: '2009/12/09',
                    salary: '$85'
                },
                {
                    name: 'Howard Hatfield',
                    position: 'Office Manager',
                    office: 'San Francisco',
                    age: '51',
                    date: '2008/12/16',
                    salary: '$164'
                },
                {
                    name: 'Hope Fuentes',
                    position: 'Secretary',
                    office: 'San Francisco',
                    age: '41',
                    date: '2010/02/12',
                    salary: '$109'
                },
                {
                    name: 'Vivian Harrell',
                    position: 'Financial Controller',
                    office: 'San Francisco',
                    age: '62',
                    date: '2009/02/14',
                    salary: '$452'
                },
                {
                    name: 'Timothy Mooney',
                    position: 'Office Manager',
                    office: 'London',
                    age: '37',
                    date: '2008/12/11',
                    salary: '$136'
                },
                {
                    name: 'Jackson Bradshaw',
                    position: 'Director',
                    office: 'New York',
                    age: '65',
                    date: '2008/09/26',
                    salary: '$645'
                },
                {
                    name: 'Olivia Liang',
                    position: 'Support Engineer',
                    office: 'Singapore',
                    age: '64',
                    date: '2011/02/03',
                    salary: '$234'
                },
                {
                    name: 'Bruno Nash',
                    position: 'Software Engineer',
                    office: 'London',
                    age: '38',
                    date: '2011/05/03',
                    salary: '$163'
                },
                {
                    name: 'Sakura Yamamoto',
                    position: 'Support Engineer',
                    office: 'Tokyo',
                    age: '37',
                    date: '2009/08/19',
                    salary: '$139'
                },
                {
                    name: 'Thor Walton',
                    position: 'Developer',
                    office: 'New York',
                    age: '61',
                    date: '2013/08/11',
                    salary: '$98'
                },
                {
                    name: 'Finn Camacho',
                    position: 'Support Engineer',
                    office: 'San Francisco',
                    age: '47',
                    date: '2009/07/07',
                    salary: '$87'
                },
                {
                    name: 'Serge Baldwin',
                    position: 'Data Coordinator',
                    office: 'Singapore',
                    age: '64',
                    date: '2012/04/09',
                    salary: '$138'
                },
                {
                    name: 'Zenaida Frank',
                    position: 'Software Engineer',
                    office: 'New York',
                    age: '63',
                    date: '2010/01/04',
                    salary: '$125'
                },
                {
                    name: 'Zorita Serrano',
                    position: 'Software Engineer',
                    office: 'San Francisco',
                    age: '56',
                    date: '2012/06/01',
                    salary: '$115'
                },
                {
                    name: 'Jennifer Acosta',
                    position: 'Junior Javascript Developer',
                    office: 'Edinburgh',
                    age: '43',
                    date: '2013/02/01',
                    salary: '$75'
                },
                {
                    name: 'Cara Stevens',
                    position: 'Sales Assistant',
                    office: 'New York',
                    age: '46',
                    date: '2011/12/06',
                    salary: '$145'
                },
                {
                    name: 'Hermione Butler',
                    position: 'Regional Director',
                    office: 'London',
                    age: '47',
                    date: '2011/03/21',
                    salary: '$356'
                },
                {
                    name: 'Lael Greer',
                    position: 'Systems Administrator',
                    office: 'London',
                    age: '21',
                    date: '2009/02/27',
                    salary: '$103'
                },
                {
                    name: 'Jonas Alexander',
                    position: 'Developer',
                    office: 'San Francisco',
                    age: '30',
                    date: '2010/07/14',
                    salary: '$86'
                },
                {
                    name: 'Shad Decker',
                    position: 'Regional Director',
                    office: 'Edinburgh',
                    age: '51',
                    date: '2008/11/13',
                    salary: '$183'
                },
                {
                    name: 'Michael Bruce',
                    position: 'Javascript Developer',
                    office: 'Singapore',
                    age: '29',
                    date: '2011/06/27',
                    salary: '$183'
                },
                {
                    name: 'Donna Snider',
                    position: 'Customer Support',
                    office: 'New York',
                    age: '27',
                    date: '2011/01/25',
                    salary: '$112'
                }
            ]
        };
        return (
            <React.Fragment>
                <Row>
                    <Col md="4">
                        <Card className="card-icon rt_icon_card mb-4 mt-mob-4 text-center">
                            <CardBody>
                                <div className="icon_rt">
                                    <i className="feather ft-users"></i>
                                </div>
                                <div className="icon_specs">
                                    <p>New Users</p>
                                    <span>57893</span>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-icon rt_icon_card mb-4 text-center">
                            <CardBody>
                                <div className="icon_rt">
                                    <i className="feather ft-activity"></i>
                                </div>
                                <div className="icon_specs">
                                    <p>User Activity</p>
                                    <span>670</span>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-icon rt_icon_card mb-4 text-center">
                            <CardBody>
                                <div className="icon_rt">
                                    <i className="feather ft-shopping-bag"></i>
                                </div>
                                <div className="icon_specs">
                                    <p>Total Sales</p>
                                    <span>9466</span>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
                <Row>
                    <Col md="4" className="stretched_card">
                        <Card>
                            <h5 className="card-header">Top Campaigns</h5>
                            <CardBody>
                                <Table responsive className="table no-wrap p-table mb-0">
                                    <thead className="bg-light">
                                        <tr className="border-0">
                                            <th className="border-0">Campaign</th>
                                            <th className="border-0">Visits</th>
                                            <th className="border-0">Revenue</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Campaign#1</td>
                                            <td>98,789 </td>
                                            <td>$4563</td>
                                        </tr>
                                        <tr>
                                            <td>Campaign#2</td>
                                            <td>2,789 </td>
                                            <td>$325</td>
                                        </tr>
                                        <tr>
                                            <td>Campaign#3</td>
                                            <td>1,459 </td>
                                            <td>$225</td>
                                        </tr>
                                        <tr>
                                            <td>Campaign#4</td>
                                            <td>5,035 </td>
                                            <td>$856</td>
                                        </tr>
                                        <tr>
                                            <td>Campaign#5</td>
                                            <td>5,035 </td>
                                            <td>$856</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3" className="pb-0">
                                                <Link to="#" className="btn btn-outline-primary float-right">Details</Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4" className="mt-mob-4 stretched_card">
                        <div className="team_member">
                            <img src={member_1} alt="Team Member" />
                            <div className="member_name">
                                <h3>JHON DOE</h3>
                                <span>CEO/Founder</span>
                            </div>
                            <p>It is a long established fact that a reader will be distracted by the readable content distracted by the readable content.</p>
                            <ul>
                                <li><Link to="#"><i className="fa fa-facebook"></i></Link></li>
                                <li><Link to="#"><i className="fa fa-twitter"></i></Link></li>
                                <li><Link to="#"><i className="fa fa-google-plus"></i></Link></li>
                                <li><Link to="#"><i className="fa fa-linkedin"></i></Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col md="4" className="mt-mob-4 stretched_card">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">User Stats</h4>
                                <Pie />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <h5 className="card-header"> Traffic By Social Media</h5>
                            <CardBody className="p-0">
                                <ul className="social-sales list-group list-group-flush">
                                    <li className="list-group-item social-sales-content"><span className="social-sales-icon-circle facebook-bgcolor mr-2"><i className="fa fa-facebook-f"></i></span><span className="social-sales-name">Facebook</span><span className="social-sales-count text-dark">120 Sales</span>
                                    </li>
                                    <li className="list-group-item social-sales-content"><span className="social-sales-icon-circle twitter-bgcolor mr-2"><i className="fa fa-twitter"></i></span><span className="social-sales-name">Twitter</span><span className="social-sales-count text-dark">99 Sales</span>
                                    </li>
                                    <li className="list-group-item social-sales-content"><span className="social-sales-icon-circle pinterest-bgcolor mr-2"><i className="fa fa-pinterest-p"></i></span><span className="social-sales-name">Pinterest</span><span className="social-sales-count text-dark">56 Sales</span>
                                    </li>
                                    <li className="list-group-item social-sales-content"><span className="social-sales-icon-circle googleplus-bgcolor mr-2"><i className="fa fa-google-plus"></i></span><span className="social-sales-name">Google Plus</span><span className="social-sales-count text-dark">36 Sales</span>
                                    </li>
                                </ul>
                            </CardBody>
                            <CardFooter className="text-center">
                                <Link to="#" className="btn-primary-link">View Details</Link>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="6" className="mt-mob-4 mt-4">
                        <Row>
                            <div className="col-md-4 mt-mob-4">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-download"></i>
                                        <p>Total Downloads</p>
                                        <span>670</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-mob-4">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-shopping-cart-full"></i>
                                        <p>Products Sold</p>
                                        <span>9869</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-mob-4">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-user"></i>
                                        <p>Total users</p>
                                        <span>4567</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-5">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-image"></i>
                                        <p>Total Images</p>
                                        <span>4568</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-5">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-stats-up"></i>
                                        <p>Statics</p>
                                        <span>9873</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-5">
                                <div className="card card-icon text-center">
                                    <div className="card-body">
                                        <i className="ti-cup"></i>
                                        <p>Achievements</p>
                                        <span>6543</span>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>
                
                <Row>
                    <Col className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">DATA TABLE DEFAULT</h4>
                                <div className="datatable_container">
                                    <MDBDataTable
                                        responsive
                                        bordered
                                        data={data}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
                <Row>
                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Timeline</h4>
                                <div className="pl-5">
                                    <div className="timeline-task">
                                        <div className="icon bg_secondary">
                                            <i className="feather ft-message-square"></i>
                                        </div>
                                        <div className="timeline_title">
                                            <h4>You got 1 New Message</h4>
                                            <span className="time"><i className="ti-time"></i>09:35</span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse distinctio itaque at.
                                    </p>
                                    </div>
                                    <div className="timeline-task">
                                        <div className="icon bg_success">
                                            <i className="feather ft-check"></i>
                                        </div>
                                        <div className="timeline_title">
                                            <h4>Your Verification Successful</h4>
                                            <span className="time"><i className="ti-time"></i>7 Minutes Ago</span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur.
                                    </p>
                                    </div>
                                    <div className="timeline-task">
                                        <div className="icon bg_danger">
                                            <i className="feather ft-alert-triangle"></i>
                                        </div>
                                        <div className="timeline_title">
                                            <h4>Something Went Wrong</h4>
                                            <span className="time"><i className="ti-time"></i>09:20 Am</span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse distinctio itaque at.</p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="6" className="stretched_card mt-4">
                        <Card>
                            <CardBody>
                                <h4 className="card_title">Sales By Traffic</h4>
                                <ul className="traffic-sales list-group list-group-flush">
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Direct</span>
                                        <span className="traffic-sales-amount">$4000.00
                                        <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                                <i className="fa fa-fw fa-arrow-up"></i>
                                            </span>
                                            <span className="ml-1 text-success">5.86%</span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item">
                                        <span className="traffic-sales-name">Search
                                        <span className="traffic-sales-amount">$3123.00
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                                    <i className="fa fa-fw fa-arrow-up"></i>
                                                </span>
                                                <span className="ml-1 text-success">5.86%</span>
                                            </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item">
                                        <span className="traffic-sales-name">Social
                                        <span className="traffic-sales-amount ">$3099.00
                                            <span className="icon-circle-small icon-box-xs text-success ml-4 bg-success-light">
                                                    <i className="fa fa-fw fa-arrow-up"></i>
                                                </span>
                                                <span className="ml-1 text-success">5.86%</span>
                                            </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item">
                                        <span className="traffic-sales-name">Referrals
                                        <span className="traffic-sales-amount ">$2220.00
                                            <span className="icon-circle-small icon-box-xs text-danger ml-4 bg-danger-light">
                                                    <i className="fa fa-fw fa-arrow-down"></i>
                                                </span>
                                                <span className="ml-1 text-danger">4.02%</span>
                                            </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Email
                                        <span className="traffic-sales-amount">$1567.00
                                            <span className="icon-circle-small icon-box-xs text-danger ml-4 bg-danger-light">
                                                    <i className="fa fa-fw fa-arrow-down"></i>
                                                </span>
                                                <span className="ml-1 text-danger">3.86%</span>
                                            </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Visits
                                        <span className="traffic-sales-amount">$1567.00
                                            <span className="icon-circle-small icon-box-xs text-danger ml-4 bg-danger-light">
                                                    <i className="fa fa-fw fa-arrow-down"></i>
                                                </span>
                                                <span className="ml-1 text-danger">3.86%</span>
                                            </span>
                                        </span>
                                    </li>
                                    <li className="traffic-sales-content list-group-item ">
                                        <span className="traffic-sales-name">Marketing
                                        <span className="traffic-sales-amount">$1567.00
                                            <span className="icon-circle-small icon-box-xs text-danger ml-4 bg-danger-light">
                                                    <i className="fa fa-fw fa-arrow-down"></i>
                                                </span>
                                                <span className="ml-1 text-danger">3.86%</span>
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { activateAuthLayout })(DashboardV3));